<template>
    <div class="contact">
        <main>
        <!--? Hero Start -->
        <div class="slider-area2">
            <div class="slider-height2 d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap hero-cap2 pt-20 text-center">
                                <h2 style="color: white;">加入我們</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Hero End -->
        <!--?  Contact Area start  -->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="contact-title">個人信息填寫</h2>
                        <h3>感謝您報名第十三屆單車天使！本活動報名截至日期為2025年03月31日24時，請認真了解報名要求并仔細填寫以下表格：</h3>
                        <br><br>
                    </div>
                    <div class="col-lg-8">
                        <a-form @submit="handleSubmit" :form="form">
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="姓名"
                            >
                            <a-input
                                v-decorator="[
                                'name',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item 
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="性別">
                            <a-select
                                v-decorator="[
                                'gender',
                                { rules: [{ required: true, message: '請選擇内容' }] },
                                ]"
                            >
                                <a-select-option value="男">
                                男
                                </a-select-option>
                                <a-select-option value="女">
                                女
                                </a-select-option>
                            </a-select>
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="出生年月"
                            >
                            <a-date-picker 
                            :locale="locale" 
                            v-decorator="[
                                'age',
                                { rules: [{  required: true, message: '請輸入内容' }] },
                                ]"/>
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="政治面貌"
                            >
                            <a-input
                                v-decorator="[
                                'status',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="民族"
                            >
                            <a-input
                                v-decorator="[
                                'nation',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item 
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="籍貫">
                                <a-cascader
                                v-decorator="[
                                    'native',
                                    {
                                    rules: [
                                        { type: 'array', required: true, message: '請選擇内容'},
                                    ],
                                    },
                                ]"
                                :options="areaData"
                                />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="家庭所在地"
                            >
                            <a-input
                                v-decorator="[
                                'home',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="是否吸烟"
                            >
                            <a-radio-group  v-decorator="[
                                    'smoke',
                                    {
                                    rules: [
                                        { required: true, message: '请选择' },
                                    ],
                                    },
                                ]">
                                    <a-radio value="是" name="type">是</a-radio>
                                    <a-radio value="否" name="type">否</a-radio>
                                </a-radio-group>
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="是否有心腦血管疾病"
                            >
                            <a-radio-group  v-decorator="[
                                    'cacd',
                                    {
                                    rules: [
                                        { required: true, message: '请选择' },
                                    ],
                                    },
                                ]">
                                    <a-radio value="是" name="type">是</a-radio>
                                    <a-radio value="否" name="type">否</a-radio>
                                </a-radio-group>
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="是否有慢性病"
                            >
                            <a-radio-group  v-decorator="[
                                    'cd',
                                    {
                                    rules: [
                                        { required: true, message: '请选择' },
                                    ],
                                    },
                                ]">
                                    <a-radio value="是" name="type">是</a-radio>
                                    <a-radio value="否" name="type">否</a-radio>
                                </a-radio-group>
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="是否畢業"
                            >
                            <a-radio-group 
                            v-decorator="[
                                'isgraduate',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                                @change="isGraduate"
                                >
                                <a-radio value="是">是</a-radio>
                                <a-radio value="否">否</a-radio>
                                <br>
                                <a-card hoverable style="width:100%">
                                    <p v-if="isGraduateBool==1">
                                        <a-form-item label="畢業院校&現長居地">
                                            <a-input
                                            v-decorator="[
                                            'college',
                                            { rules: [{ required: false, message: '請輸入内容' }] },
                                            ]"
                                            placeholder="畢業院校"
                                            />
                                            <a-input
                                            v-decorator="[
                                            'living',
                                            { rules: [{ required: false, message: '請輸入内容' }] },
                                            ]"
                                            placeholder="現長居地"
                                            />
                                        </a-form-item>
                                    </p>
                                    <p v-else-if="isGraduateBool==2">
                                        <a-form-item label="就讀院校&專業&院校所在地">
                                            <a-input
                                            v-decorator="[
                                            'college',
                                            { rules: [{ required: false, message: '請輸入内容' }] },
                                            ]"
                                            placeholder="就讀院校"
                                            />
                                            <a-input
                                            v-decorator="[
                                            'grade',
                                            { rules: [{ required: false, message: '請輸入内容' }] },
                                            ]"
                                            placeholder="年級"
                                            />
                                            <a-input
                                            v-decorator="[
                                            'collegepos',
                                            { rules: [{ required: false, message: '請輸入内容' }] },
                                            ]"
                                            placeholder="院校所在地"
                                            />
                                        </a-form-item>
                                    </p>
                                    <p v-else-if="isGraduateBool==0"></p>
                                </a-card>
                            </a-radio-group>
                            </a-form-item>

                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="就讀時專業"
                            >
                            <a-input
                                v-decorator="[
                                'major',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="愛好"
                            >
                            <a-input
                                v-decorator="[
                                'hobby',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="聯係方式"
                            >
                            <a-input
                                v-decorator="[
                                'email',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                                placeholder="郵箱"
                            />
                            <a-input
                                v-decorator="[
                                'phone',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                                placeholder="電話"
                            />
                            <a-input
                                v-decorator="[
                                'wechat',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                                placeholder="微信"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="緊急聯係人/方式"
                            >
                            <a-input
                                v-decorator="[
                                'linkugentman',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                                placeholder="聯係人"
                            /><a-input
                                v-decorator="[
                                'linkugentway',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                                placeholder="聯係方式"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="報名意向"
                            >
                            <a-radio-group 
                            v-decorator="[
                                'isline',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                                @change="isLineRadio"
                                >
                                <a-radio value="綫下">綫下志愿</a-radio>
                                <a-radio value="綫上">綫上志愿</a-radio>
                                <a-card hoverable style="width:100%">
                                    <p v-if="isLineBool == 1">
                                        <a-form-item
                                        label="第一志愿"
                                        >
                                            <a-radio-group  v-decorator="[
                                                'groupone',
                                                {
                                                rules: [
                                                    { required: false, message: '请选择' },
                                                ],
                                                },
                                            ]"
                                            >
                                                <a-radio value="骑行组" name="type">骑行组</a-radio>
                                                <a-radio value="影音组" name="type">影音组</a-radio>
                                                <a-radio value="运营组" name="type">运营组</a-radio>
                                                <a-radio value="美工组" name="type">美工组</a-radio>
                                                <a-radio value="医护组" name="type">医护组</a-radio>
                                                <a-radio value="网络组" name="type">网络组</a-radio>
                                                <a-radio value="财务助理" name="type">财务助理</a-radio>
                                            </a-radio-group>
                                        </a-form-item>
                                        <a-form-item
                                        label="第二志愿"
                                        >
                                            <a-radio-group  v-decorator="[
                                                'grouptwo',
                                                {
                                                rules: [
                                                    { required: false, message: '请选择' },
                                                ],
                                                },
                                            ]">
                                                <a-radio value="骑行组" name="type">骑行组</a-radio>
                                                <a-radio value="影音组" name="type">影音组</a-radio>
                                                <a-radio value="运营组" name="type">运营组</a-radio>
                                                <a-radio value="美工组" name="type">美工组</a-radio>
                                                <a-radio value="医护组" name="type">医护组</a-radio>
                                                <a-radio value="网络组" name="type">网络组</a-radio>
                                                <a-radio value="财务助理" name="type">财务助理</a-radio>
                                            </a-radio-group>
                                        </a-form-item>
                                    </p>
                                    <p v-else-if="isLineBool == 2">
                                        <a-form-item
                                        label="第一志愿"
                                        >
                                            <a-radio-group  v-decorator="[
                                                'grouponeup',
                                                {
                                                rules: [
                                                    { required: false, message: '请选择' },
                                                ],
                                                },
                                            ]">
                                                <a-radio value="运营" name="type">运营</a-radio>
                                                <a-radio value="美工" name="type">美工</a-radio>
                                                <a-radio value="网络" name="type">网络</a-radio>
                                                <a-radio value="财务助理" name="type">财务助理</a-radio>
                                                <a-radio value="破风鸭（线上教师）" name="type">破风鸭（线上教师）</a-radio>
                                            </a-radio-group>
                                        </a-form-item>
                                        <a-form-item
                                        label="第二志愿"
                                        >
                                            <a-radio-group  v-decorator="[
                                                'grouptwoup',
                                                {
                                                rules: [
                                                    { required: false, message: '请选择' },
                                                ],
                                                },
                                            ]">
                                                <a-radio value="运营" name="type">运营</a-radio>
                                                <a-radio value="美工" name="type">美工</a-radio>
                                                <a-radio value="网络" name="type">网络</a-radio>
                                                <a-radio value="财务助理" name="type">财务助理</a-radio>
                                                <a-radio value="破风鸭（线上教师）" name="type">破风鸭（线上教师）</a-radio>
                                            </a-radio-group>
                                        </a-form-item>
                                    </p>
                                    <p v-else></p>
                                </a-card>
                            </a-radio-group>
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="附加技能（如教學技能等）"
                            >
                            <a-checkbox-group  v-decorator="[
                                'skillschoice',
                                { rules: [{ type:'array', required: true, message: '請選擇内容' }] },
                                ]">
                                <a-checkbox value="行政技能：善于团队生活安排与管理；拥有良好沟通能力、遇事冷静，拥有团队管理经验。" name="type">
                                行政技能：善于团队生活安排与管理；拥有良好沟通能力、遇事冷静，拥有团队管理经验。
                                </a-checkbox>
                                <a-checkbox value="财务技能：熟悉日常财务管理工作，能够优化财务管理模式；工作严谨、细致，原则性强。" name="type">
                                财务技能：熟悉日常财务管理工作，能够优化财务管理模式；工作严谨、细致，原则性强。
                                </a-checkbox>
                                <a-checkbox value="外联技能：善于与企业、组织沟通、对接，思维活跃，能够根据企业要求策划统筹活动；沟通能力强。" name="type">
                                外联技能：善于与企业、组织沟通、对接，思维活跃，能够根据企业要求策划统筹活动；沟通能力强。
                                </a-checkbox>
                                <a-checkbox value="教学技能：师范类专业，或者具有教学相关经历；与学生互动能力强。" name="type">
                                教学技能：师范类专业，或者具有教学相关经历；与学生互动能力强。
                                </a-checkbox>
                                <a-checkbox value="其他：如书法、绘画、跳舞、乐器等。" name="type">
                                其他：如书法、绘画、跳舞、乐器等。
                                </a-checkbox>
                            </a-checkbox-group>
                            <a-textarea
                            :auto-size="{minRows:5, maxRows:10}"
                                v-decorator="[
                                'skills',
                                { rules: [{ required: false, message: '請輸入内容' }] },
                                ]"
                                placeholder="如果還有其他技能可以寫在此輸入框内（選填）"
                            />
                            <h2>（注：附加技能的相應證明可上傳到郵箱：<b>bikeangel_b@163.com</b>） </h2>
                            </a-form-item>
                            <br>
                            <br>

                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label=" "
                            >
                            <h3>參與過的公益活動及擔任的職務</h3>
                            <a-textarea
                            :auto-size="{minRows:5, maxRows:10}"
                                v-decorator="[
                                'job',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label=" "
                            >
                            <h3>對單車天使的了解</h3>
                            <a-textarea
                                :auto-size="{minRows:5, maxRows:10}"
                                v-decorator="[
                                'description',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label=" "
                            >
                            <h3>對單車天使现有几大模块（如：破风鸭、大山公益等）的了解</h3>
                            <a-textarea
                                :auto-size="{minRows:5, maxRows:10}"
                                v-decorator="[
                                'module',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label=" "
                            >
                            <h3>對單車天使帮助对象的了解</h3>
                            <a-textarea
                                :auto-size="{minRows:5, maxRows:10}"
                                v-decorator="[
                                'behelp',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label=" "
                            >
                            <h3>爲什麽報名參加單車天使</h3>
                            <a-textarea
                                :auto-size="{minRows:5, maxRows:10}"
                                v-decorator="[
                                'reason',
                                { rules: [{ required: true, message: '請輸入内容' }] },
                                ]"
                            />
                            </a-form-item>
                            <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="您從哪些渠道了解到本次活動？"
                            >
                            <a-checkbox-group  v-decorator="[
                                'way',
                                { rules: [{ type:'array', required: false, message: '請選擇内容' }] },
                                ]">
                                <a-checkbox value="BiliBili - 单车天使" name="type">
                                BiliBili - 单车天使
                                </a-checkbox>
                                <br>
                                <a-checkbox value="抖音 - 单车天使Bike Angel" name="type">
                                抖音 - 单车天使Bike Angel
                                </a-checkbox>
                                <br>
                                <a-checkbox value="小紅書 - 单车天使" name="type">
                                小紅書 - 单车天使
                                </a-checkbox>
                                <br>
                                <a-checkbox value="微信公公衆號 - 单车天使" name="type">
                                微信公公衆號 - 单车天使
                                </a-checkbox>
                                <br>
                                <a-checkbox value="FaceBook - 台湾单车天使协会" name="type">
                                FaceBook - 台湾单车天使协会
                                </a-checkbox>
                                <br>                               
                                <a-checkbox value="網站" name="type">
                                单车天使網站
                                </a-checkbox>
                                 <a-textarea
                                 :auto-size="{minRows:5, maxRows:10}"
                                v-decorator="[
                                'waydesc',
                                { rules: [{  required: false, message: '請輸入内容' }] },
                                ]"
                                placeholder="其他方式，如果有则附上账号名称"
                            />
                            </a-checkbox-group>
                            </a-form-item>
                            <a-form-item 
                            label="個人圖片" 
                                :label-col="formTailLayout.labelCol" 
                                :wrapper-col="formTailLayout.wrapperCol">
                                <a-upload
                                    name="avatar"
                                    list-type="picture-card"
                                    class="avatar-uploader"
                                    :show-upload-list="false"
                                    :customRequest="uploadImg"
                                    :before-upload="beforeUpload"
                                >
                                    <img v-if="form.image" :src="form.image" width="240px" height="135px" alt="avatar" />
                                    <div v-else>
                                    <a-icon :type="loading ? 'loading' : 'plus'" />
                                    <div class="ant-upload-text">
                                        上傳圖片
                                    </div>
                                    </div>
                                </a-upload>
                            </a-form-item>
                            <a-form-item :label-col="formTailLayout.labelCol" :wrapper-col="formTailLayout.wrapperCol">
                            <a-button type="primary" htmlType="submit">提交</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="col-lg-3 offset-lg-1">
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-email"></i></span>
                            <div class="media-body">
                                <h3>bikeangel_b@163.com</h3>
                                <p>我們一直在路上等你</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-comments"></i></span>
                            <div class="media-body">
                                <h3>聯係單小天微信，了解招募流程</h3>
                                <img style="width: 90%;" src="../static/img/contact/DuckXT.jpg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact Area End -->
    </main>
    </div>
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import items from '../static/js/region'


const formItemLayout = {
labelCol: { span: 8 },
wrapperCol: { span: 15 },
};
const formTailLayout = {
labelCol: { span: 6 },
wrapperCol: { span: 8, offset: 4 },
};


export default {
 

data() {
  return {
    locale,
    data: [],
    areaData: items,
    oldData: [],
    formItemLayout, 
    formTailLayout,
    loading: false,
    isLineBool:0,
    isGraduateBool:0,
    groupEmail:0,
    form: this.$form.createForm(this, { name: 'dynamic_rule' }),
  };
},

methods: {
    isLineRadio(e) {
      if(e.target.value==='綫下'){
        this.isLineBool = 1;
      }
      else if(e.target.value==='綫上'){
        this.isLineBool = 2;
      }
      else{
        this.isLineBool = 0;
      }
    },
    isGraduate(e){
        if(e.target.value==='是'){
            this.isGraduateBool = 1;
        }
        else if(e.target.value==='否'){
            this.isGraduateBool = 2;
        }
        else{
            this.isGraduateBool = 0;
        }
    },
    

  //提交表單到後端
  async handleSubmit(e){
    console.log("ok")
    e.preventDefault();
    await this.form.validateFields((err, fieldsValue)=>{
        // console.log(fieldsValue)
        if(err){
            console.log(err)
            console.log('未輸入');
            return;
        }
        fieldsValue.skillschoice = fieldsValue.skillschoice.join(' ');
        fieldsValue.image = this.form.image;
        fieldsValue.imagename = this.form.imagename;
        fieldsValue.area = fieldsValue.native[0];
        fieldsValue.native = fieldsValue.native.join(' ');
        fieldsValue.way = fieldsValue.way.join(' ');
        for(var i=0; i<fieldsValue.length; i++){
            if(fieldsValue[i].isline==='綫上'){
                fieldsValue[i].groupone = '无';
                fieldsValue[i].grouptwo = '无';
            }
            if(fieldsValue[i].isline==='綫下'){
                fieldsValue[i].grouponeup = '无';
                fieldsValue[i].grouptwoup = '无';
            }
        }
        console.log("表單内容"+fieldsValue);
        //提交後端
        if(fieldsValue){
            console.log("開始提交")
            this.$http({
            method: 'POST',
            url:'/applies/info',
            data:fieldsValue
            })
            .then(res=>{
            this.$message.success('上傳成功');
            this.$router.push('/hello');
            console.log(res);
            })
            .catch(err=>{
            this.$message.error('上傳失敗');
            console.log(err);
            });
            
        }
    })
  },

  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('圖片格式需要為 JPG/PNG !');
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('圖片大小需要小于 5MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    uploadImg(file){
      this.loading = true;
      const formData = new FormData();
      formData.append("file",file.file);
      this.$http({
        method: 'POST',
        url:'/articleImgUpload',
        data: formData,
      }).then(res=>{
        // this.form.image = res.data.url;
        this.form.image = res.data.url;
        this.form.imagename = res.data.name
        console.log(this.form.imagename);
        console.log(this.form.image);
        this.loading = false;
        this.$message.success('圖片上传成功')
      }).catch(err=>{
        this.$message.error('圖片上传失败')
      })
    }
  
    
  },



};
</script>

<style>
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>