var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('main',[_vm._m(0),_c('section',{staticClass:"contact-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-lg-8"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'name',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"性別"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'gender',
                            { rules: [{ required: true, message: '請選擇内容' }] },
                            ]),expression:"[\n                            'gender',\n                            { rules: [{ required: true, message: '請選擇内容' }] },\n                            ]"}]},[_c('a-select-option',{attrs:{"value":"男"}},[_vm._v(" 男 ")]),_c('a-select-option',{attrs:{"value":"女"}},[_vm._v(" 女 ")])],1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"出生年月"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'age',
                            { rules: [{  required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'age',\n                            { rules: [{  required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"locale":_vm.locale}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"政治面貌"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'status',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'status',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"民族"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'nation',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'nation',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"籍貫"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'native',
                                {
                                rules: [
                                    { type: 'array', required: true, message: '請選擇内容'},
                                ],
                                },
                            ]),expression:"[\n                                'native',\n                                {\n                                rules: [\n                                    { type: 'array', required: true, message: '請選擇内容'},\n                                ],\n                                },\n                            ]"}],attrs:{"options":_vm.areaData}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"家庭所在地"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'home',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'home',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"是否吸烟"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'smoke',
                                {
                                rules: [
                                    { required: true, message: '请选择' },
                                ],
                                },
                            ]),expression:"[\n                                'smoke',\n                                {\n                                rules: [\n                                    { required: true, message: '请选择' },\n                                ],\n                                },\n                            ]"}]},[_c('a-radio',{attrs:{"value":"是","name":"type"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"否","name":"type"}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"是否有心腦血管疾病"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'cacd',
                                {
                                rules: [
                                    { required: true, message: '请选择' },
                                ],
                                },
                            ]),expression:"[\n                                'cacd',\n                                {\n                                rules: [\n                                    { required: true, message: '请选择' },\n                                ],\n                                },\n                            ]"}]},[_c('a-radio',{attrs:{"value":"是","name":"type"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"否","name":"type"}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"是否有慢性病"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                'cd',
                                {
                                rules: [
                                    { required: true, message: '请选择' },
                                ],
                                },
                            ]),expression:"[\n                                'cd',\n                                {\n                                rules: [\n                                    { required: true, message: '请选择' },\n                                ],\n                                },\n                            ]"}]},[_c('a-radio',{attrs:{"value":"是","name":"type"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"否","name":"type"}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"是否畢業"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'isgraduate',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'isgraduate',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],on:{"change":_vm.isGraduate}},[_c('a-radio',{attrs:{"value":"是"}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":"否"}},[_vm._v("否")]),_c('br'),_c('a-card',{staticStyle:{"width":"100%"},attrs:{"hoverable":""}},[(_vm.isGraduateBool==1)?_c('p',[_c('a-form-item',{attrs:{"label":"畢業院校&現長居地"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'college',
                                        { rules: [{ required: false, message: '請輸入内容' }] },
                                        ]),expression:"[\n                                        'college',\n                                        { rules: [{ required: false, message: '請輸入内容' }] },\n                                        ]"}],attrs:{"placeholder":"畢業院校"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'living',
                                        { rules: [{ required: false, message: '請輸入内容' }] },
                                        ]),expression:"[\n                                        'living',\n                                        { rules: [{ required: false, message: '請輸入内容' }] },\n                                        ]"}],attrs:{"placeholder":"現長居地"}})],1)],1):(_vm.isGraduateBool==2)?_c('p',[_c('a-form-item',{attrs:{"label":"就讀院校&專業&院校所在地"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'college',
                                        { rules: [{ required: false, message: '請輸入内容' }] },
                                        ]),expression:"[\n                                        'college',\n                                        { rules: [{ required: false, message: '請輸入内容' }] },\n                                        ]"}],attrs:{"placeholder":"就讀院校"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'grade',
                                        { rules: [{ required: false, message: '請輸入内容' }] },
                                        ]),expression:"[\n                                        'grade',\n                                        { rules: [{ required: false, message: '請輸入内容' }] },\n                                        ]"}],attrs:{"placeholder":"年級"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'collegepos',
                                        { rules: [{ required: false, message: '請輸入内容' }] },
                                        ]),expression:"[\n                                        'collegepos',\n                                        { rules: [{ required: false, message: '請輸入内容' }] },\n                                        ]"}],attrs:{"placeholder":"院校所在地"}})],1)],1):(_vm.isGraduateBool==0)?_c('p'):_vm._e()])],1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"就讀時專業"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'major',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'major',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"愛好"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'hobby',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'hobby',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"聯係方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'email',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"placeholder":"郵箱"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'phone',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'phone',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"placeholder":"電話"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'wechat',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'wechat',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"placeholder":"微信"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"緊急聯係人/方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'linkugentman',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'linkugentman',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"placeholder":"聯係人"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'linkugentway',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'linkugentway',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"placeholder":"聯係方式"}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"報名意向"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'isline',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'isline',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],on:{"change":_vm.isLineRadio}},[_c('a-radio',{attrs:{"value":"綫下"}},[_vm._v("綫下志愿")]),_c('a-radio',{attrs:{"value":"綫上"}},[_vm._v("綫上志愿")]),_c('a-card',{staticStyle:{"width":"100%"},attrs:{"hoverable":""}},[(_vm.isLineBool == 1)?_c('p',[_c('a-form-item',{attrs:{"label":"第一志愿"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'groupone',
                                            {
                                            rules: [
                                                { required: false, message: '请选择' },
                                            ],
                                            },
                                        ]),expression:"[\n                                            'groupone',\n                                            {\n                                            rules: [\n                                                { required: false, message: '请选择' },\n                                            ],\n                                            },\n                                        ]"}]},[_c('a-radio',{attrs:{"value":"骑行组","name":"type"}},[_vm._v("骑行组")]),_c('a-radio',{attrs:{"value":"影音组","name":"type"}},[_vm._v("影音组")]),_c('a-radio',{attrs:{"value":"运营组","name":"type"}},[_vm._v("运营组")]),_c('a-radio',{attrs:{"value":"美工组","name":"type"}},[_vm._v("美工组")]),_c('a-radio',{attrs:{"value":"医护组","name":"type"}},[_vm._v("医护组")]),_c('a-radio',{attrs:{"value":"网络组","name":"type"}},[_vm._v("网络组")]),_c('a-radio',{attrs:{"value":"财务助理","name":"type"}},[_vm._v("财务助理")])],1)],1),_c('a-form-item',{attrs:{"label":"第二志愿"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'grouptwo',
                                            {
                                            rules: [
                                                { required: false, message: '请选择' },
                                            ],
                                            },
                                        ]),expression:"[\n                                            'grouptwo',\n                                            {\n                                            rules: [\n                                                { required: false, message: '请选择' },\n                                            ],\n                                            },\n                                        ]"}]},[_c('a-radio',{attrs:{"value":"骑行组","name":"type"}},[_vm._v("骑行组")]),_c('a-radio',{attrs:{"value":"影音组","name":"type"}},[_vm._v("影音组")]),_c('a-radio',{attrs:{"value":"运营组","name":"type"}},[_vm._v("运营组")]),_c('a-radio',{attrs:{"value":"美工组","name":"type"}},[_vm._v("美工组")]),_c('a-radio',{attrs:{"value":"医护组","name":"type"}},[_vm._v("医护组")]),_c('a-radio',{attrs:{"value":"网络组","name":"type"}},[_vm._v("网络组")]),_c('a-radio',{attrs:{"value":"财务助理","name":"type"}},[_vm._v("财务助理")])],1)],1)],1):(_vm.isLineBool == 2)?_c('p',[_c('a-form-item',{attrs:{"label":"第一志愿"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'grouponeup',
                                            {
                                            rules: [
                                                { required: false, message: '请选择' },
                                            ],
                                            },
                                        ]),expression:"[\n                                            'grouponeup',\n                                            {\n                                            rules: [\n                                                { required: false, message: '请选择' },\n                                            ],\n                                            },\n                                        ]"}]},[_c('a-radio',{attrs:{"value":"运营","name":"type"}},[_vm._v("运营")]),_c('a-radio',{attrs:{"value":"美工","name":"type"}},[_vm._v("美工")]),_c('a-radio',{attrs:{"value":"网络","name":"type"}},[_vm._v("网络")]),_c('a-radio',{attrs:{"value":"财务助理","name":"type"}},[_vm._v("财务助理")]),_c('a-radio',{attrs:{"value":"破风鸭（线上教师）","name":"type"}},[_vm._v("破风鸭（线上教师）")])],1)],1),_c('a-form-item',{attrs:{"label":"第二志愿"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                            'grouptwoup',
                                            {
                                            rules: [
                                                { required: false, message: '请选择' },
                                            ],
                                            },
                                        ]),expression:"[\n                                            'grouptwoup',\n                                            {\n                                            rules: [\n                                                { required: false, message: '请选择' },\n                                            ],\n                                            },\n                                        ]"}]},[_c('a-radio',{attrs:{"value":"运营","name":"type"}},[_vm._v("运营")]),_c('a-radio',{attrs:{"value":"美工","name":"type"}},[_vm._v("美工")]),_c('a-radio',{attrs:{"value":"网络","name":"type"}},[_vm._v("网络")]),_c('a-radio',{attrs:{"value":"财务助理","name":"type"}},[_vm._v("财务助理")]),_c('a-radio',{attrs:{"value":"破风鸭（线上教师）","name":"type"}},[_vm._v("破风鸭（线上教师）")])],1)],1)],1):_c('p')])],1)],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"附加技能（如教學技能等）"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'skillschoice',
                            { rules: [{ type:'array', required: true, message: '請選擇内容' }] },
                            ]),expression:"[\n                            'skillschoice',\n                            { rules: [{ type:'array', required: true, message: '請選擇内容' }] },\n                            ]"}]},[_c('a-checkbox',{attrs:{"value":"行政技能：善于团队生活安排与管理；拥有良好沟通能力、遇事冷静，拥有团队管理经验。","name":"type"}},[_vm._v(" 行政技能：善于团队生活安排与管理；拥有良好沟通能力、遇事冷静，拥有团队管理经验。 ")]),_c('a-checkbox',{attrs:{"value":"财务技能：熟悉日常财务管理工作，能够优化财务管理模式；工作严谨、细致，原则性强。","name":"type"}},[_vm._v(" 财务技能：熟悉日常财务管理工作，能够优化财务管理模式；工作严谨、细致，原则性强。 ")]),_c('a-checkbox',{attrs:{"value":"外联技能：善于与企业、组织沟通、对接，思维活跃，能够根据企业要求策划统筹活动；沟通能力强。","name":"type"}},[_vm._v(" 外联技能：善于与企业、组织沟通、对接，思维活跃，能够根据企业要求策划统筹活动；沟通能力强。 ")]),_c('a-checkbox',{attrs:{"value":"教学技能：师范类专业，或者具有教学相关经历；与学生互动能力强。","name":"type"}},[_vm._v(" 教学技能：师范类专业，或者具有教学相关经历；与学生互动能力强。 ")]),_c('a-checkbox',{attrs:{"value":"其他：如书法、绘画、跳舞、乐器等。","name":"type"}},[_vm._v(" 其他：如书法、绘画、跳舞、乐器等。 ")])],1),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'skills',
                            { rules: [{ required: false, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'skills',\n                            { rules: [{ required: false, message: '請輸入内容' }] },\n                            ]"}],attrs:{"auto-size":{minRows:5, maxRows:10},"placeholder":"如果還有其他技能可以寫在此輸入框内（選填）"}}),_c('h2',[_vm._v("（注：附加技能的相應證明可上傳到郵箱："),_c('b',[_vm._v("bikeangel_b@163.com")]),_vm._v("） ")])],1),_c('br'),_c('br'),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":" "}},[_c('h3',[_vm._v("參與過的公益活動及擔任的職務")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'job',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'job',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"auto-size":{minRows:5, maxRows:10}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":" "}},[_c('h3',[_vm._v("對單車天使的了解")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'description',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'description',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"auto-size":{minRows:5, maxRows:10}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":" "}},[_c('h3',[_vm._v("對單車天使现有几大模块（如：破风鸭、大山公益等）的了解")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'module',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'module',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"auto-size":{minRows:5, maxRows:10}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":" "}},[_c('h3',[_vm._v("對單車天使帮助对象的了解")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'behelp',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'behelp',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"auto-size":{minRows:5, maxRows:10}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":" "}},[_c('h3',[_vm._v("爲什麽報名參加單車天使")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'reason',
                            { rules: [{ required: true, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'reason',\n                            { rules: [{ required: true, message: '請輸入内容' }] },\n                            ]"}],attrs:{"auto-size":{minRows:5, maxRows:10}}})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"您從哪些渠道了解到本次活動？"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'way',
                            { rules: [{ type:'array', required: false, message: '請選擇内容' }] },
                            ]),expression:"[\n                            'way',\n                            { rules: [{ type:'array', required: false, message: '請選擇内容' }] },\n                            ]"}]},[_c('a-checkbox',{attrs:{"value":"BiliBili - 单车天使","name":"type"}},[_vm._v(" BiliBili - 单车天使 ")]),_c('br'),_c('a-checkbox',{attrs:{"value":"抖音 - 单车天使Bike Angel","name":"type"}},[_vm._v(" 抖音 - 单车天使Bike Angel ")]),_c('br'),_c('a-checkbox',{attrs:{"value":"小紅書 - 单车天使","name":"type"}},[_vm._v(" 小紅書 - 单车天使 ")]),_c('br'),_c('a-checkbox',{attrs:{"value":"微信公公衆號 - 单车天使","name":"type"}},[_vm._v(" 微信公公衆號 - 单车天使 ")]),_c('br'),_c('a-checkbox',{attrs:{"value":"FaceBook - 台湾单车天使协会","name":"type"}},[_vm._v(" FaceBook - 台湾单车天使协会 ")]),_c('br'),_c('a-checkbox',{attrs:{"value":"網站","name":"type"}},[_vm._v(" 单车天使網站 ")]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'waydesc',
                            { rules: [{  required: false, message: '請輸入内容' }] },
                            ]),expression:"[\n                            'waydesc',\n                            { rules: [{  required: false, message: '請輸入内容' }] },\n                            ]"}],attrs:{"auto-size":{minRows:5, maxRows:10},"placeholder":"其他方式，如果有则附上账号名称"}})],1)],1),_c('a-form-item',{attrs:{"label":"個人圖片","label-col":_vm.formTailLayout.labelCol,"wrapper-col":_vm.formTailLayout.wrapperCol}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"avatar","list-type":"picture-card","show-upload-list":false,"customRequest":_vm.uploadImg,"before-upload":_vm.beforeUpload}},[(_vm.form.image)?_c('img',{attrs:{"src":_vm.form.image,"width":"240px","height":"135px","alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" 上傳圖片 ")])],1)])],1),_c('a-form-item',{attrs:{"label-col":_vm.formTailLayout.labelCol,"wrapper-col":_vm.formTailLayout.wrapperCol}},[_c('a-button',{attrs:{"type":"primary","htmlType":"submit"}},[_vm._v("提交")])],1)],1)],1),_vm._m(2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider-area2"},[_c('div',{staticClass:"slider-height2 d-flex align-items-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"hero-cap hero-cap2 pt-20 text-center"},[_c('h2',{staticStyle:{"color":"white"}},[_vm._v("加入我們")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"contact-title"},[_vm._v("個人信息填寫")]),_c('h3',[_vm._v("感謝您報名第十三屆單車天使！本活動報名截至日期為2025年03月31日24時，請認真了解報名要求并仔細填寫以下表格：")]),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 offset-lg-1"},[_c('div',{staticClass:"media contact-info"},[_c('span',{staticClass:"contact-info__icon"},[_c('i',{staticClass:"ti-email"})]),_c('div',{staticClass:"media-body"},[_c('h3',[_vm._v("bikeangel_b@163.com")]),_c('p',[_vm._v("我們一直在路上等你")])])]),_c('div',{staticClass:"media contact-info"},[_c('span',{staticClass:"contact-info__icon"},[_c('i',{staticClass:"ti-comments"})]),_c('div',{staticClass:"media-body"},[_c('h3',[_vm._v("聯係單小天微信，了解招募流程")]),_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../static/img/contact/DuckXT.jpg"),"alt":""}})])])])
}]

export { render, staticRenderFns }